<template>
             <!-- v-if="eventDrag!=event&&comPlace.id == event.comPlace.id 
      && day.date == $tools.dateTimeFormat(event.begin, 'YYYY-MM-DD')
      ||eventDrag==event&&comPlace.id == eventDrag.comPlace.id 
      && day.date == $tools.dateTimeFormat(eventDrag.begin, 'YYYY-MM-DD')"     -->
  <div>
    <template v-for="event in events">
    <div class="schedule-item"
                                
      v-if="showCard(event)"
      :key="'e_'+event.id"
      :style="eventCardStyle(event, day.timeSlots[0])"          
      @mousemove.stop="$emit('hideTooltip')" 
      @mousedown.right.prevent="loadState.isSuccess ? $emit('startDrag',event) : null"  
       
      @click.stop="loadState.isSuccess&&keyPressed===false ? 
      userSpecPlanState.modalOpen(Object.assign({}, event, {dayTimeEnd: day.timeSlots[day.timeSlots.length-1],dayTimeBegin: day.timeSlots[0]})) 
      : keyPressed ? $emit('startCopy', $event, event) : null"

      >
    
          <tooltip bottom v-if="eventCardStyle(event).width.replace(/\D/g,'')<=180||zoom>1">
            <div class="schedule-item__doc-tooltip">
                <v-avatar color="user" size="50">
                    <img :src="event.userSpec.user.imageUrl"  v-if="event.userSpec.user.imageUrl" alt="">
                </v-avatar>
            </div>
            <template v-slot:content>
              <div class="schedule-item__doc">                        
                <v-avatar color="user" size="50">
                    <img :src="event.userSpec.user.imageUrl"  v-if="event.userSpec.user.imageUrl" alt="">
                </v-avatar>

                <div class="ml-2">
                  <div class="schedule-item__doc-name" :title="$tools.userFullName(event.userSpec.user)">{{$tools.userFullName(event.userSpec.user,true)}}</div>
                  <div class="schedule-item__doc-spec">
                    <v-avatar :color="event.userSpec.comDiv.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
                    {{event.userSpec.spec.name}}
                  </div>
                  <div class="schedule-item__doc-time">{{$tools.dateTimeFormat(event.begin, 'HH:mm')}} - {{$tools.dateTimeFormat(event.end, 'HH:mm')}}
                    <v-icon dense color="black" v-if="event.type === 'eat'">mdi-coffee</v-icon>
                    <v-icon dense color="black" v-if="event.type === 'break'">mdi-palm-tree</v-icon>
                  </div>
                </div>

              </div>
            </template>
          </tooltip>
   
          <div v-else class="schedule-item__doc">              
            <v-avatar color="user" size="50">
                <img :src="event.userSpec.user.imageUrl"  v-if="event.userSpec.user.imageUrl" alt="">
            </v-avatar>

            <div class="ml-2">
              <div class="schedule-item__doc-name" :title="$tools.userFullName(event.userSpec.user)">{{$tools.userFullName(event.userSpec.user,true)}}</div>
              <div class="schedule-item__doc-spec">
                <v-avatar :color="event.userSpec.comDiv.comBranch.color" size="10" class="mr-1" style="margin-top:-2px"/>
                {{event.userSpec.spec.name}}
              </div>
              <div class="schedule-item__doc-time">{{$tools.dateTimeFormat(event.begin, 'HH:mm')}} - {{$tools.dateTimeFormat(event.end, 'HH:mm')}}
                <v-icon dense color="black" v-if="event.type === 'eat'">mdi-coffee</v-icon>
                <v-icon dense color="black" v-if="event.type === 'break'">mdi-palm-tree</v-icon>
              </div>
            </div>

          </div>
        <div class="schedule-item__stretching"  @mousedown.left.prevent="loadState.isSuccess ? startStratching(event) : null"></div>
    </div>
    </template>                       
  </div>  

</template>


<script>
import moment from "moment";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  props: ['events','comPlace','zoom' ,'day', 'keyPressed', 'loadState', 'userSpecPlanState', 'eventDrag','eventStretching', 'eventCopy'],
  components: {
    Tooltip,
  },
  data: () => ({

  }),
  // watch: {
  // },
  methods: {
    showCard(event){
      if (event.comPlace.id === this.comPlace.id&&this.$tools.dateTimeFormat(event.begin, 'YYYY-MM-DD')===this.day.date) {
        return true;
      } else {
        return false;
      }
    },
    scheduleCardOffset(dayBegin,userDayBegin) {
      let duration = moment(userDayBegin, "HH:mm").diff(moment(dayBegin, "HH:mm"), 'minutes')
      return duration
    },
    eventCardStyle(event, workDayBegin) {
      let zoom = 1;
      if (this.zoom>1) {
        zoom = 4;
      }
      let duration = moment(event.end).diff(moment(event.begin), 'minutes')/zoom
      let offsetLeft = this.scheduleCardOffset(workDayBegin,this.$tools.dateTimeFormat(event.begin, 'HH:mm'))/zoom
      let width = duration + 'px'
      let borderLeft = '5px solid '+event.userSpec.spec.color
      let transition
      let background
      let opacity
      let zindex

      let pointerEvents

      if (event.type === 'break'||event.type === 'eat'){
        background = '#fdda00';
      }
      if (this.eventDrag.length>0) {
        pointerEvents = 'none'

        this.$emit('cursorStyle','move');
        transition = 'background .5s linear, opacity .5s linear' 
        zindex = 0   
        if (event.id == this.eventDrag[0].id) {         
          zindex = 99
          opacity = .8
        }
      }
      else if (this.eventStretching.length>0) {
        pointerEvents = 'none'
        this.$emit('cursorStyle','ew-resize');
        transition = 'unset'
        zindex = 0   
        if (event.id == this.eventStretching[0].id) {         
          zindex = 99
        }
      }
      else if (this.eventCopy&&event.id===this.eventCopy.id) {
        background = '#428fda'
      }
      else {
        pointerEvents = 'all'
        this.$emit('cursorStyle','pointer');
        transition = 'all .3s'
      }         
      
      return {
        transform: 'translate3d('+offsetLeft+'px, 0, 0)',
        width: width,
        borderLeft:borderLeft,
        pointerEvents:pointerEvents,
        background:background,
        opacity: opacity,
        zIndex:zindex,
        transition:transition
      };
    },
    startStratching(event) {
      const comBranchId = this.comPlace.comRoom.comFloor.comBuilding.comBranch.id;
      const eventDate = moment(event.begin).format('YYYY-MM-DD')
      const minTime = event.begin;
      const maxTime= moment(eventDate+' '+this.day.comBranches.filter(cb=>cb.id===comBranchId)[0].end,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'); 

      const eventStetchingMinMaxTime = {
        minTime:minTime,
        maxTime:maxTime,
      }

      this.$emit('startStretching',event,eventStetchingMinMaxTime);
    }
  }
}
</script>

<style lang="scss" scoped>
    .schedule-item {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 100%;
      width: 0;
      transform: translate3d(0, 0, 0);
      z-index: 99;
      background: rgb(245, 245, 245);
      box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
      cursor: pointer;
      transition: height .3s;
      &__stretching {
        background: #d9d8d8;
        position: absolute;
        top:0;
        right: 0;
        width:5px;
        height:100%;
        z-index: 10;
        cursor:ew-resize;
        &:hover {
          background: #858282;
        }
      }
      &__doc-tooltip {
        position: absolute;
        inset: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
      }
      &__doc-time {
        font-weight: 600;
        font-size: .825rem;
      }
      &__doc {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .825rem;
        &-name {
          font-weight: 600;
          white-space: nowrap;
        }
        &-spec {
          font-weight: normal;
          white-space: nowrap;
        }
        img{
          object-fit: cover;
        }
      }
    }  
</style>